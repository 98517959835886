<script>
import Navbar from "../components/Navbar.vue";
import Footer from "../components/Footer.vue";
import axios from "axios";
import store from "@/state/store";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      timeSlots: [],
      date: null,
      booked: [],
      userId: store.getters["user/id"],
      userName: store.getters["user/name"],
      yourName: "",
      reasons: "",
      selectedSlot: "",
      docData:[],
      config:[]
    };
  },
  components: {
    Navbar,
    Footer,
  },

  methods: {
    async getDoctor(){
      const res = await axios.get("https://api.doctosoft.com/index.php/api/doctor/"+ this.$route.params.id)
      this.docData = res.data
      console.log(this.docData)
    },
    async getBookedSlot() {
      const response = await axios.get(
        "https://api.doctosoft.com/index.php/api/book/appointment/"+ this.docData.id +'/' + this.date 
      );
      this.booked = response.data;
      this.getSlots();
      console.log(this.booked);
    },
    async getSlots() {
      const response = await axios.get("https://api.doctosoft.com/index.php/api/app/config/");
      this.config = response.data.config;

      this.timeSlots = [];
      const startTime = this.config.startTime;
      const endTime = this.config.endTime;
      const timeSlotDuration = parseInt(this.config.interval); // duration of each time slot in minutes

      let currentTime = startTime;

      while (currentTime < endTime) {
        const start = currentTime;
        const end = addMinutes(currentTime, timeSlotDuration);
        if(end > endTime)
        {
          break;
        }
        const timeSlot = start + "-" + end;
        this.timeSlots.push(timeSlot);
        currentTime = end;
      }
      function addMinutes(time, minutes) {
        const [hours, mins] = time.split(":").map(Number);
        const timeInMinutes = hours * 60 + mins + minutes;
        const newHours = Math.floor(timeInMinutes / 60);
        const newMins = timeInMinutes % 60;
        return `${newHours.toString().padStart(2, "0")}:${newMins
          .toString()
          .padStart(2, "0")}`;
      }
    },
    position(time,date) {
      Swal.fire({
        position: "top-center",
        icon: "success",
        title: "Your Appointment Has been Booked at "+time+" on"+" " +date,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    async handleSubmit() {
      // console.log(this.date)
      // console.log(this.yourName)
      // console.log(this.selectedSlot)
      // console.log(this.reasons)

      var formdata = new FormData();
      formdata.append("doctor", this.docData.id);
      formdata.append("user", this.userId);
      formdata.append("date", this.date);
      formdata.append("time", this.selectedSlot);
      formdata.append("reason", this.reasons);
      formdata.append("status", "new");

      await axios.post(
        "https://api.doctosoft.com/index.php/api/appointment",
        formdata
      );  
      this.$router.push('/')
      this.position(this.selectedSlot,this.date)
    },
 
  },

  created() {
    let currentDate = new Date();
    let nextDate = new Date();
    nextDate.setDate(currentDate.getDate() + 7);
    let startYear = currentDate.getFullYear();
    let startMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    let startDay = currentDate.getDate().toString().padStart(2, "0");
    let endYear = nextDate.getFullYear();
    let endMonth = (nextDate.getMonth() + 1).toString().padStart(2, "0");
    let endDay = nextDate.getDate().toString().padStart(2, "0");
    this.startDate = `${startYear}-${startMonth}-${startDay}`;
    this.endDate = `${endYear}-${endMonth}-${endDay}`;
    this.getDoctor()
  },
};
</script>

<template>
  <Navbar />
  <div class="container appointment">
    <div class="form row docData">
      <h1>Book Appointment of {{ docData.name }}<strong class="docDegree">.{{ docData.expert }}</strong></h1>
    </div>
    <form class="needs-validation" @submit.prevent="handleSubmit">
      <div class="row form">
        <b-col md="6" class="form-column">
          <input type="hidden" name="doctor" :value="docData.id" />
          <input type="hidden" name="user" :value="userId" />

          <div>
            <label for="nameInput" class="form-label"
              >Name <span>*</span></label
            >
            <input
              type="text"
              class="form-control"
              id="nameInput"
              placeholder="Your Name"
              v-model="userName"
            />
          </div>
        </b-col>
        <b-col md="6" class="form-column">
          <div>
            <label for="exampleInputdate" class="form-label"
              >Preferred Date <span>*</span></label
            >
            <input
              type="date"
              :min="startDate"
              :max="endDate"
              @change="getBookedSlot"
              v-model="date"
              class="form-control"
              id="exampleInputdate"
            />
          </div>
        </b-col>
        <b-col md="12" class="form-column">
          <div>
            <div class="time">
              <label for="appointmentTime" class="form-label"
                >Preferred Time <span>*</span></label
              >
            </div>
            <b-card no-body>
              <p v-if="!this.date">Please Select Date</p>
              <div class="time-slots">
                <div v-for="slot in timeSlots" :key="slot" class="slot-item">
                  <input
                    type="radio"
                    :id="slot"
                    name="date"
                    :value="slot"
                    :disabled="booked.includes(slot)"
                    v-model="selectedSlot"
                  />
                  <label :for="slot"> {{ slot }}</label>
                </div>
              </div>
            </b-card>
          </div>
        </b-col>
      </div>
      <div class="row form">
        <b-col xxl="12" md="12" class="form-column">
          <div>
            <label for="reasons" class="form-label"
              >Reasons <span>(optional)</span></label
            >
            <textarea
              class="form-control"
              id="reasons"
              rows="3"
              v-model="reasons"
            ></textarea>
          </div>
        </b-col>
      </div>
      <div class="row form-btn">
        <b-button variant="success" class="submitBtn" type="submit"
          >Add</b-button
        >
      </div>
    </form>
  </div>

  <Footer />
</template>

<style >
.appointment {
  padding: 30px 10px 30px 10px;
}

.appointment .docData strong.docDegree{
  font-size: 18px;
  font-weight: 400;
}
.appointment .form label span {
  color: red;
}
.appointment .form {
  margin: 10px 0;
  justify-content: center;
}
.appointment .form-btn {
  padding: 0 20px;
  height: 40px;
  margin-top: 10px;
}
.appointment .form .form-column {
  margin: 10px 0 0 0;
}

.form-column .time-slots {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  row-gap: 5px;
}
.form-column .time-slots .slot-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-column .card {
  margin: 0;
}
.form-column .card p {
  margin: 0;
}

.form-column input[type="radio"] {
  visibility: hidden;
  height: 0;
  width: 0;
}
.form-column .card label {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  /* background-color: #454545; */
  border: 1px solid #454545;
  color: black;
  padding: 5px 10px;
  border-radius: 3px;
  width: 170px;
  transition: 0.2s ease;
  border-radius: 20px;
}

.form-column input[type="radio"]:checked + label {
  background-color: #32a7dd;
}
.form-column input[type="radio"][disabled] + label {
  background-color: #ccc;
  color: black;
  position: relative;
  cursor: default;
  overflow: hidden;
}
.form-column input[type="radio"][disabled] + label:before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 13px;
  height: 3px;
  transform: rotateZ(6deg);
  background-color: #e6e6e6;
  /* box-shadow: 0 2px 5px rgba(0,0,0,0.2); */
}
</style>
